"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCodeImg = getCodeImg;
exports.getInfo = getInfo;
exports.getRouters = getRouters;
exports.login = login;
exports.logout = logout;
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// 登录方法
// export function login(username, password) {
//   const data = {
//     username,
//     password
//   }
//   return request({
//     url: '/api/admin/login',
//     method: 'post',
//     data
//   })
// }

// 获取用户详细信息
function getInfo() {
  return (0, _request.default)({
    url: '/api/passport/getInfo',
    method: 'get'
  });
}

// 退出方法
function logout() {
  return (0, _request.default)({
    url: '/api/passport/logout',
    method: 'post'
  });
}

// 获取验证码
function getCodeImg() {
  return (0, _request.default)({
    url: '/captchaImage',
    method: 'get'
  });
}

// 获取登录
function login(obj) {
  var data = _objectSpread({}, obj);
  return (0, _request.default)({
    url: '/api/oauth/login',
    method: 'post',
    data: data
  }, 'login');
}

// 获取路由菜单
function getRouters(obj) {
  var params = _objectSpread({}, obj);
  return (0, _request.default)({
    url: '/api/vwk/login_column_tree',
    method: 'get',
    params: params
  }, 'login');
}