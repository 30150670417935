"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.regTime = exports.platform = void 0;
var regTime = exports.regTime = {
  86400: '24小时',
  172800: '2天内',
  432000: '5天内',
  604800: '一周内',
  1209600: '两周内',
  1296000: '半个月内',
  2592000: '一个月内',
  7776000: '三个月内',
  15811200: '半年内',
  31536000: '一年内'
};
var platform = exports.platform = {
  '2234': '体育平台',
  '6234': '电竞平台',
  '1234': '真人游戏',
  '5234': '棋牌游戏',
  '3234': '电子游戏'
};