"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _axios2 = _interopRequireDefault(require("axios"));
var _qs = _interopRequireDefault(require("qs"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _nodes = require("stylus/lib/nodes");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var getBaseURL = function getBaseURL(stage) {
  var res = '';
  if (stage == 'image') {
    res = process.env.VUE_APP_BASE_API_IMAGE;
  } else if (stage == 'login') {
    res = process.env.VUE_APP_BASE_API_LOGIN;
  } else if (stage == 'adminTokenUrl') {
    res = process.env.VUE_APP_VN_ACTIVITY;
  } else {
    res = process.env.VUE_APP_BASE_API;
  }
  return res;
};
var config = {
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: process.env.VUE_APP_PROXY ? process.env.VUE_APP_PROXY : process.env.VUE_APP_BASE_API,
  timeout: 0,
  // Timeout
  // withCredentials: true, // Check cross-site Access-Control
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
    // "Cache-Control": "no-cache",
    // 'Access-Control-Allow-Headers': 'Content-Type',
    // 'Content-Type': 'application/json;charset=utf-8'
  }
};
// 创建axios实例
var _axios = _axios2.default.create(config);
// request拦截器
_axios.interceptors.request.use(function (config) {
  if (config.iscontentType) {
    config.headers['Content-Type'] = "application/json";
    config.data = config.data;
  } else {
    if (config.method !== "get" && config.headers['Content-Type'] !== "multipart/form-data") {
      config.data = _qs.default.stringify(config.data);
    }
  }
  if ((0, _auth.getOAToken)()) {
    config.headers['Authorization'] = 'Bearer ' + (0, _auth.getOAToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
    config.headers['token'] = (0, _auth.getOAToken)();
    config.headers['group'] = (0, _auth.getRole)();
    config.headers['user'] = (0, _auth.getUser)();
  }
  return config;
}, function (error) {
  Promise.reject(error);
});

// 响应拦截器
_axios.interceptors.response.use(function (res) {
  // 二次修改 直接返还结果 不进行判断
  // return res.data
  var code = res.data.code;
  if ([3, 406, 400].includes(code)) {
    _elementUi.MessageBox.confirm('登录状态已过期,请重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      _store.default.dispatch('FedLogOut').then(function () {
        location.href = "/login"; // 为了重新实例化vue-router对象 避免bug
      });
    });
  } else {
    return res.data;
  }
}, function (error) {
  if (error.response && error.response.status == 400) {
    _store.default.dispatch('FedLogOut').then(function () {
      location.href = "/login"; // 为了重新实例化vue-router对象 避免bug
    });
  } else {
    (0, _elementUi.Message)({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
  }
  return Promise.reject(error);
});
var request = function request(data) {
  var stage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var contentType = arguments.length > 2 ? arguments[2] : undefined;
  var reqData = _objectSpread({}, data);
  reqData.url = getBaseURL(stage) + reqData.url;
  reqData.iscontentType = contentType ? true : false;
  return _axios(reqData);
};
var _default = exports.default = request;