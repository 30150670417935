"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOAToken = getOAToken;
exports.getRole = getRole;
exports.getToken = getToken;
exports.getUser = getUser;
exports.getUserId = getUserId;
exports.removeOAToken = removeOAToken;
exports.removeRole = removeRole;
exports.removeToken = removeToken;
exports.removeUser = removeUser;
exports.removeUserId = removeUserId;
exports.setOAToken = setOAToken;
exports.setRole = setRole;
exports.setToken = setToken;
exports.setUser = setUser;
exports.setUserId = setUserId;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var TokenKey = 'Admin-Token';
var UserKey = 'username';
var roleKey = 'role';
var OATokenKey = 'OA-Token';
var userId = 'userId';
function getOAToken() {
  return _jsCookie.default.get(OATokenKey);
}
function setOAToken(token) {
  return _jsCookie.default.set(OATokenKey, token);
}
function removeOAToken() {
  return _jsCookie.default.remove(OATokenKey);
}
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}
function getUser() {
  return _jsCookie.default.get(UserKey);
}
function setUser(username) {
  return _jsCookie.default.set(UserKey, username);
}
function removeUser() {
  return _jsCookie.default.remove(UserKey);
}
function getUserId() {
  return _jsCookie.default.get(userId);
}
function setUserId(uid) {
  return _jsCookie.default.set(userId, uid);
}
function removeUserId() {
  return _jsCookie.default.remove(userId);
}
function getRole() {
  return _jsCookie.default.get(roleKey);
}
function setRole(role) {
  return _jsCookie.default.set(roleKey, role);
}
function removeRole() {
  return _jsCookie.default.remove(roleKey);
}