"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/core-js/modules/es6.array.iterator.js");
require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/core-js/modules/es6.promise.js");
require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/core-js/modules/es6.object.assign.js");
require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./assets/styles/element-variables.scss");
require("@/assets/styles/index.scss");
require("@/assets/styles/common.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _permission = _interopRequireDefault(require("./directive/permission"));
require("./assets/icons");
require("./permission");
var _common2 = require("@/utils/common");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _common3 = _interopRequireDefault(require("./mixins/common"));
var globalFilters = _interopRequireWildcard(require("./filters/common"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
// a modern alternative to CSS resets

// global css

// icon
// permission control

// 全局方法挂载
_vue.default.prototype.parseTime = _common2.parseTime;
_vue.default.prototype.resetForm = _common2.resetForm;
_vue.default.prototype.addDateRange = _common2.addDateRange;
_vue.default.prototype.selectDictLabel = _common2.selectDictLabel;
_vue.default.prototype.download = _common2.download;
_vue.default.prototype.ERR_OK = 200;
_vue.default.prototype.bus = new _vue.default();
_vue.default.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'success'
  });
};
_vue.default.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'error'
  });
};
_vue.default.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
};

// 全局组件挂载
_vue.default.component('Pagination', _Pagination.default);
_vue.default.use(_permission.default);
_vue.default.mixin(_common3.default);
Object.keys(globalFilters).forEach(function (key) {
  _vue.default.filter(key, globalFilters[key]);
});

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

_vue.default.use(_elementUi.default, {
  size: 'small' // set element-ui default size
});
_vue.default.config.productionTip = false;
_vue.default.config.devtools = true;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});