"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var dataConfig = {
  namespaced: true,
  state: {
    routerData: []
  },
  getters: {},
  mutations: {
    editRouterData: function editRouterData(state, payload) {
      state.routerData = (0, _toConsumableArray2.default)(payload);
    }
  },
  actions: {
    editRouterData: function editRouterData(_ref, payload) {
      var commit = _ref.commit;
      commit('editRouterData', payload);
    }
  }
};
var _default = exports.default = dataConfig;