"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _auth = require("@/utils/auth");
var _login = _interopRequireDefault(require("@service/login"));
var _elementUi = require("element-ui");
var _login2 = require("@/api/login");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var user = {
  state: {
    token: (0, _auth.getToken)(),
    name: '',
    avatar: '',
    roles: [],
    permissions: []
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_ID: function SET_ID(state, expires_in) {
      state.expires_in = expires_in;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_AVATAR: function SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;
      return new Promise(function (resolve, reject) {
        var password = userInfo.password,
          username = userInfo.username,
          pid = userInfo.pid;
        (0, _login2.login)(_objectSpread({}, userInfo)).then(function (res) {
          if (res.code === 0) {
            var _string = "";
            if (res.data.auth_group_list.length > 0) {
              var _arr = res.data.auth_group_list.map(function (item) {
                return item.name;
              });
              _string = encodeURIComponent(_arr.toString());
            }
            (0, _auth.setRole)(_string);
            (0, _auth.setOAToken)(res.data.access_token);
            (0, _auth.setUser)(username);
            (0, _auth.setUserId)(res.data.uid);
            _login.default.getToken({
              login_name: username,
              password: password,
              role_names: decodeURIComponent(_string)
            }).then(function (res2) {
              if (res2.code === 200) {
                var token = res2.data.token;
                var platform_id = res2.data.platform_id;
                localStorage.setItem('token', token);
                localStorage.setItem('platform_id', platform_id);
                (0, _auth.setToken)(token);
                commit('SET_TOKEN', token);
                resolve(res2);
              } else {
                _elementUi.Notification.error({
                  title: res2.message
                });
              }
            }).catch(function (e) {
              _elementUi.Notification.error({
                title: e.message
              });
            });
          } else {
            if (![402, 407].includes(res.code)) {
              _elementUi.Notification.error({
                title: res.msg
              });
              reject(e);
              return;
            }
            resolve(res);
          }
        }).catch(function (e) {
          reject(e);
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref2) {
      var commit = _ref2.commit,
        state = _ref2.state;
      return new Promise(function (resolve, reject) {
        // 二次修改 抛出用户信息，不进行请求
        var res = {
          code: 0,
          data: {
            username: '',
            role_id: 0
          }
        };
        var _res = {
          'msg': '操作成功',
          'code': 200,
          'permissions': ['*:*:*'],
          'roles': ['admin'],
          'user': {
            'searchValue': null,
            'createBy': 'admin',
            'createTime': '2018-03-16 11:33:00',
            'updateBy': null,
            'updateTime': null,
            'remark': '管理员',
            'dataScope': null,
            'params': {},
            'userId': 1,
            'deptId': 103,
            'userName': 'admin',
            'nickName': 'dancen',
            'email': 'dancen@ecoph.com',
            'phonenumber': '15888888888',
            'sex': '1',
            'avatar': '',
            'password': '$2a$10$7JB720yubVSZvUI0rEqK/.VqGOZTH.ulu33dHOiBE8ByOhJIrdAu2',
            'salt': null,
            'status': '0',
            'delFlag': '0',
            'loginIp': '127.0.0.1',
            'loginDate': '2018-03-16T11:33:00.000+0800',
            'dept': {
              'searchValue': null,
              'createBy': null,
              'createTime': null,
              'updateBy': null,
              'updateTime': null,
              'remark': null,
              'dataScope': null,
              'params': {},
              'deptId': 103,
              'parentId': 101,
              'ancestors': null,
              'deptName': '研发部门',
              'orderNum': '1',
              'leader': 'dancen',
              'phone': null,
              'email': null,
              'status': '0',
              'delFlag': null,
              'parentName': null,
              'children': []
            },
            'roles': [{
              'searchValue': null,
              'createBy': null,
              'createTime': null,
              'updateBy': null,
              'updateTime': null,
              'remark': null,
              'dataScope': '1',
              'params': {},
              'roleId': 1,
              'roleName': '管理员',
              'roleKey': 'admin',
              'roleSort': '1',
              'status': '0',
              'delFlag': null,
              'flag': false,
              'menuIds': null,
              'deptIds': null,
              'admin': true
            }],
            'roleIds': null,
            'postIds': null,
            'admin': true
          }
        };
        var user = _res.user;
        if (_res.roles && _res.roles.length > 0) {
          // 验证返回的roles是否是一个非空数组
          commit('SET_ROLES', [res.data.role_id]);
          commit('SET_PERMISSIONS', _res.permissions);
        } else {
          commit('SET_ROLES', ['ROLE_DEFAULT']);
        }
        commit('SET_NAME', res.data.username);
        var avatar = require('@/assets/image/male.png');
        commit('SET_AVATAR', avatar);
        resolve(res);
      });
    },
    // 退出系统
    LogOut: function LogOut(_ref3) {
      var commit = _ref3.commit,
        state = _ref3.state;
      return new Promise(function (resolve, reject) {
        localStorage.removeItem('token');
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        commit('SET_PERMISSIONS', []);
        (0, _auth.removeToken)();
        resolve();
        return;
      });
    },
    // 前端 登出
    FedLogOut: function FedLogOut(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '');
        (0, _auth.removeToken)();
        resolve();
      });
    }
  }
};
var _default = exports.default = user;