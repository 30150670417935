"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadView = exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _router2 = require("@/router");
var _index = _interopRequireDefault(require("@/layout/index"));
var _login = require("@/api/login");
var _router = {
  msg: "操作成功",
  code: 200,
  data: [{
    path: "/activityManage",
    name: "activityManage",
    component: _index.default,
    meta: {
      title: "活动配置管理"
    },
    children: [{
      name: "new-turntable",
      path: "new-turntable",
      component: "activity-manage/new-turntable",
      meta: {
        title: "新转盘活动"
      }
    }, {
      name: "new-first-deposit",
      path: "new-first-deposit",
      component: "activity-manage/new-first-deposit",
      meta: {
        title: "100%首存"
      }
    }, {
      name: "continue-deposit",
      path: "continue-deposit",
      component: "activity-manage/continue-deposit",
      meta: {
        title: "续存活动"
      }
    }, {
      name: "vt-first-deposit",
      path: "vt-first-deposit",
      component: "activity-manage/vt-first-deposit",
      meta: {
        title: "VT首存"
      }
    }, {
      name: "vt-continue-deposit",
      path: "vt-continue-deposit",
      component: "activity-manage/vt-continue-deposit",
      meta: {
        title: "VT续存"
      }
    }, {
      name: "vt-newbie-double-gift",
      path: "vt-newbie-double-gift",
      component: "activity-manage/vt-newbie-double-gift",
      meta: {
        title: "VT新人双重礼"
      }
    }, {
      name: "vt-mid-autumn-golden",
      path: "vt-mid-autumn-golden",
      component: "activity-manage/vt-mid-autumn-golden",
      meta: {
        title: "VT春节活动"
      }
    }, {
      name: "vt-electronic-sign",
      path: "vt-electronic-sign",
      component: "activity-manage/vt-electronic-sign",
      meta: {
        title: "VT电游签到"
      }
    }, {
      name: "general-activity",
      path: "general-activity",
      component: "activity-manage/general-activity",
      meta: {
        title: "通用活动列表"
      }
    }, {
      name: "multiple-conditions",
      path: "multiple-conditions",
      component: "activity-manage/multiple-conditions",
      meta: {
        title: "多条件活动"
      }
    }, {
      name: "multiple-conditions-progressive",
      path: "multiple-conditions-progressive",
      component: "activity-manage/multiple-conditions-progressive",
      meta: {
        title: "多条件活动-递进类型"
      }
    }, {
      name: "turntable",
      path: "turntable",
      component: "activity-manage/turntable",
      meta: {
        title: "转盘活动"
      }
    }, {
      name: "profit-backwater",
      path: "profit-backwater",
      component: "activity-manage/profit-backwater",
      meta: {
        title: "负盈利返水设置"
      }
    }, {
      name: "every-deposit",
      path: "every-deposit",
      component: "activity-manage/every-deposit",
      meta: {
        title: "笔笔存活动"
      }
    }, {
      name: "total-bet",
      path: "total-bet",
      component: "activity-manage/total-bet",
      meta: {
        title: "累计投注送"
      }
    }, {
      name: "set-time-first-deposit",
      path: "set-time-first-deposit",
      component: "activity-manage/set-time-first-deposit",
      meta: {
        title: "指定时间段首存"
      }
    }, {
      name: "group-task",
      path: "group-task",
      component: "activity-manage/group-task",
      meta: {
        title: "组合任务"
      }
    }, {
      name: "bind-user-info",
      path: "bind-user-info",
      component: "activity-manage/bind-user-info",
      meta: {
        title: "绑定个人信息"
      }
    }, {
      name: "set-time-total-deposit",
      path: "set-time-total-deposit",
      component: "activity-manage/set-time-total-deposit",
      meta: {
        title: "指定时间内累计存款"
      }
    }, {
      name: "message-template",
      path: "message-template",
      component: "activity-manage/message-template",
      meta: {
        title: "领奖中心消息模板"
      }
    }, {
      name: "member-reports",
      path: "member-reports",
      component: "activity-manage/member-reports",
      meta: {
        title: "会员统计报表"
      }
    }, {
      name: "welcome-three-delivery",
      path: "welcome-three-delivery",
      component: "activity-manage/welcome-three-delivery",
      meta: {
        title: "迎新三连送"
      }
    }, {
      name: "convert-luck-money",
      path: "convert-luck-money",
      component: "activity-manage/convert-luck-money",
      meta: {
        title: "转运金活动"
      }
    }, {
      name: "christmas-draw",
      path: "christmas-draw",
      component: "activity-manage/christmas-draw",
      meta: {
        title: "圣诞抽奖活动"
      }
    }, {
      name: "goldegg-trio",
      path: "goldegg-trio",
      component: "activity-manage/goldegg-trio",
      meta: {
        title: "金蛋三重奏抽奖活动"
      }
    }, {
      name: "reward_definition_type",
      path: "reward_definition_type",
      component: "activity-manage/reward_definition_type",
      meta: {
        title: "奖励类型定义表"
      }
    }, {
      name: "invites-friends",
      path: "invites-friends",
      component: "activity-manage/invites-friends",
      meta: {
        title: "人人代理邀请好友活动"
      }
    }, {
      name: "friends-betting",
      path: "friends-betting",
      component: "activity-manage/friends-betting",
      meta: {
        title: "人人代理好友投注活动"
      }
    }]
  }, {
    path: "/reportTable",
    name: "reportTable",
    component: _index.default,
    meta: {
      title: "活动报表"
    },
    children: [{
      name: "new-turntable-report-table",
      path: "new-turntable-report-table",
      component: "report-table/new-turntable-report-table",
      meta: {
        title: "新轮盘活动表报"
      }
    }, {
      name: "new-first-deposit-report-table",
      path: "new-first-deposit-report-table",
      component: "report-table/new-first-deposit-report-table",
      meta: {
        title: "100%首存活动报表"
      }
    }, {
      name: "continue-deposit-report-table",
      path: "continue-deposit-report-table",
      component: "report-table/continue-deposit-report-table",
      meta: {
        title: "续存活动报表"
      }
    }, {
      name: "vt-first-deposit-table",
      path: "vt-first-deposit-table",
      component: "report-table/vt-first-deposit-table",
      meta: {
        title: "VT首存活动报表"
      }
    }, {
      name: "vt-continue-deposit-table",
      path: "vt-continue-deposit-table",
      component: "report-table/vt-continue-deposit-table",
      meta: {
        title: "VT续存活动报表"
      }
    }, {
      name: "vt-newbie-double-gift-report",
      path: "vt-newbie-double-gift-report",
      component: "report-table/vt-newbie-double-gift-report",
      meta: {
        title: "VT新人双重礼活动报表"
      }
    }, {
      name: "vt-mid-autumn-golden-report",
      path: "vt-mid-autumn-golden-report",
      component: "activity-manage/vt-mid-autumn-golden-report",
      meta: {
        title: "VT春节活动报表"
      }
    }, {
      name: "vt-electronic-sign-report",
      path: "vt-electronic-sign-report",
      component: "activity-manage/vt-electronic-sign-report",
      meta: {
        title: "VT电游签到报表"
      }
    }, {
      name: "multiple-conditions-report-table",
      path: "multiple-conditions-report-table",
      component: "report-table/multiple-conditions-report-table",
      meta: {
        title: "多条件活动报表"
      }
    }, {
      name: "multiple-conditions-progressive-report",
      path: "multiple-conditions-progressive-report",
      component: "report-table/multiple-conditions-progressive-report",
      meta: {
        title: "多条件活动报表-递进类型"
      }
    }, {
      name: "turnable-report-table",
      path: "turnable-report-table",
      component: "report-table/turnable-report-table",
      meta: {
        title: "大转盘活动报表"
      }
    }, {
      name: "profit-backwater-report-table",
      path: "profit-backwater-report-table",
      component: "report-table/profit-backwater-report-table",
      meta: {
        title: "负盈利返水活动报表"
      }
    }, {
      name: "every-deposit-report-table",
      path: "every-deposit-report-table",
      component: "report-table/every-deposit-report-table",
      meta: {
        title: "笔笔存活动报表"
      }
    }, {
      name: "total-bet-report-table",
      path: "total-bet-report-table",
      component: "report-table/total-bet-report-table",
      meta: {
        title: "累计投注送报表"
      }
    }, {
      name: "first-deposit-report-table",
      path: "first-deposit-report-table",
      component: "report-table/first-deposit-report-table",
      meta: {
        title: "指定时间段首存报表"
      }
    }, {
      name: "group-task-report-table",
      path: "tgroup-task-report-table",
      component: "report-table/group-task-report-table",
      meta: {
        title: "组合任务报表"
      }
    }, {
      name: "bind-user-info-report-table",
      path: "bind-user-info-report-table",
      component: "report-table/bind-user-info-report-table",
      meta: {
        title: "绑定个人信息报表"
      }
    }, {
      name: "total-deposit-report-table",
      path: "total-deposit-report-table",
      component: "report-table/total-deposit-report-table",
      meta: {
        title: "指定时间内累计存款"
      }
    }, {
      name: "welcome-three-delivery-report",
      path: "welcome-three-delivery-report",
      component: "report-table/welcome-three-delivery-report",
      meta: {
        title: "迎新三连送报表"
      }
    }, {
      name: "convert-luck-money-report",
      path: "convert-luck-money-report",
      component: "report-table/convert-luck-money-report",
      meta: {
        title: "转运金活动报表"
      }
    }, {
      name: "Christmas-draw-report",
      path: "Christmas-draw-report",
      component: "report-table/Christmas-draw-report",
      meta: {
        title: "圣诞抽奖活动报表"
      }
    }, {
      name: "goldegg-trio-report",
      path: "goldegg-trio-report",
      component: "report-table/goldegg-trio-report",
      meta: {
        title: "金蛋三重奏抽奖活动报表"
      }
    }, {
      name: "invites-friends-report",
      path: "invites-friends-report",
      component: "report-table/invites-friends-report",
      meta: {
        title: "人人代理邀请好友活动报表"
      }
    }, {
      name: "friends-betting-report",
      path: "friends-betting-report",
      component: "report-table/friends-betting-report",
      meta: {
        title: "人人代理好友投注活动报表"
      }
    }]
  }, {
    path: "/task_condition_type",
    name: "task_condition_type",
    component: _index.default,
    meta: {
      title: "任务条件类型"
    },
    children: [{
      name: "tct",
      path: "/task_condition_type",
      component: "task-condition-type",
      meta: {
        title: "任务条件类型",
        icon: ""
      }
    }]
  }, {
    path: "/grow_task_list",
    component: _index.default,
    name: "grow_task_list",
    meta: {
      title: "成长任务列表"
    },
    children: [{
      name: "growtl",
      path: "/grow_task_list",
      component: "grow-task-list",
      meta: {
        title: "成长任务列表",
        icon: ""
      }
    }]
  }, {
    path: "/grow_task_report",
    component: _index.default,
    name: "grow_task_report",
    children: [{
      name: "grow_task_report_list",
      path: "/grow_task_report_list",
      component: "grow_task_report_list",
      meta: {
        title: "成长任务报表"
      }
    }]
  }, {
    path: "/guide_task_list",
    component: _index.default,
    name: "guide_task_list",
    children: [{
      name: "gtl",
      path: "/guide_task_list",
      component: "guide-task-list",
      meta: {
        title: "新手任务列表"
      }
    }]
  }, {
    path: "/guide_task_report",
    component: _index.default,
    name: "guide_task_report",
    children: [{
      name: "guide_task_report_list",
      path: "/guide_task_report_list",
      component: "guide_task_report_list",
      meta: {
        title: "新手任务报表"
      }
    }]
  }, {
    path: "/members_task_list",
    name: "members_task_list",
    component: _index.default,
    meta: {
      title: "会员任务进度"
    },
    children: [{
      name: "mtl",
      path: "/members_task_list_plan",
      component: "members-task-list",
      meta: {
        title: "会员任务进度",
        icon: ""
      }
    }]
  }, {
    path: "/redirect_params",
    name: "redirect_params",
    component: _index.default,
    meta: {
      title: "任务跳转参数定义"
    },
    children: [{
      name: "redirect_params_sub",
      path: "/redirect_params",
      component: "redirect-params",
      meta: {
        title: "任务跳转参数定义",
        icon: ""
      }
    }]
  },
  // {
  //   'path': '/record',
  //   'component': Layout,
  //   'children': [
  //     {
  //       'name': 'record',
  //       'path': '/record',
  //       'component': 'm-coin-record',
  //       'meta': {
  //         'title': 'M币领取记录',
  //         'icon': '',
  //       }
  //     },
  //   ]
  // },

  // {
  //   'path': '/version_list',
  //   'component': Layout,
  //   'children': [
  //     {
  //       'name': 'versionList',
  //       'path': '/version_list',
  //       'component': 'version-list',
  //       'meta': {
  //         'title': '译文列表',
  //         'icon': '',
  //       }
  //     },
  //   ]
  // },
  // {
  //   'path': '/set_language',
  //   'component': Layout,
  //   'children': [
  //     {
  //       'name': 'setLanguage',
  //       'path': '/set_language',
  //       'component': 'set-language',
  //       'meta': {
  //         'title': '语种设置',
  //         'icon': '',
  //       }
  //     },
  //   ]
  // },

  {
    path: "/platform_editing",
    name: "platform_settings",
    component: _index.default,
    meta: {
      title: "平台设置"
    },
    children: [{
      name: "platform_settings_sub",
      path: "/platform_settings",
      component: "platform-settings",
      meta: {
        title: "平台设置",
        icon: ""
      }
    }]
  },
  // MX 任务活动管理
  {
    name: 'MXTaskActivity',
    path: '/mx_task_activity',
    component: _index.default,
    meta: {
      title: 'MX任务活动管理'
    },
    alwaysShow: true,
    children: [{
      name: 'AddUpLogin',
      path: 'addUpLogin',
      component: "mxTaskActivity/addUpLogin/index",
      meta: {
        title: '累计登录任务',
        isShowCurrency: true
      }
    }, {
      name: 'IntegralChange',
      path: 'integralChange',
      component: 'mxTaskActivity/integralChange/index',
      meta: {
        title: '积分变动报表',
        isShowCurrency: true
      }
    }, {
      name: 'UserIntegral',
      path: 'userIntegral',
      component: 'mxTaskActivity/userIntegral/index',
      meta: {
        title: '用户积分表',
        isShowCurrency: true
      }
    }]
  },
  // 优惠活动
  {
    name: 'SalesActive',
    path: '/activity',
    component: _index.default,
    meta: {
      title: '优惠活动'
    },
    alwaysShow: true,
    children: [{
      name: 'ActiveList',
      path: 'list',
      component: "activity/list/index",
      meta: {
        title: '活动列表',
        isShowCurrency: true
      }
    },
    // {
    //   name: 'ActiveListVN',
    //   path: 'list',
    //   component: () => import('@/views/activity/list-vn/index'),
    //   meta: { title: '活动列表', isShowCurrency: true  }
    // },
    // {
    //   name: 'ActiveFirst',
    //   path: 'first',
    //   component: () => import('@/views/activity/first/index'),
    //   meta: { title: '首存活动' }
    // },
    // {
    //   name: 'ActiveContinue',
    //   path: 'renew',
    //   component: () => import('@/views/activity/renew/index'),
    //   meta: { title: '续存活动' }
    // },
    {
      name: 'ActiveSort',
      path: 'classify',
      component: 'activity/classify/index',
      meta: {
        title: '活动分类设置',
        isShowCurrency: true
      }
    }]
  }, {
    path: "/activity_check",
    name: "activity_check",
    component: _index.default,
    meta: {
      title: "活动审核管理"
    },
    children: [{
      name: "discounts_activity_check",
      path: "/discounts_activity_check",
      component: "activity_check/discounts_activity_check",
      meta: {
        title: "优惠活动审核",
        icon: ""
      }
    }]
  }, {
    path: "/daily_tasks",
    name: "daily_tasks",
    component: _index.default,
    meta: {
      title: "新活动-每日任务"
    },
    children: [{
      name: "cumulative_deposit",
      path: "/cumulative_deposit",
      component: "daily_tasks/cumulative_deposit",
      meta: {
        title: "每日任务-累计存款",
        icon: ""
      }
    }, {
      name: "cumulative_betting",
      path: "/cumulative_betting",
      component: "daily_tasks/cumulative_betting",
      meta: {
        title: "每日任务-累计投注",
        icon: ""
      }
    }, {
      name: "cumulative_deposit_report",
      path: "/cumulative_deposit_report",
      component: "daily_tasks/cumulative_deposit_report",
      meta: {
        title: "每日任务-累计存款报表",
        icon: ""
      }
    }, {
      name: "cumulative_betting_report",
      path: "/cumulative_betting_report",
      component: "daily_tasks/cumulative_betting_report",
      meta: {
        title: "每日任务-累计投注报表",
        icon: ""
      }
    }]
  }, {
    path: "/system",
    component: _index.default,
    redirect: "noredirect",
    alwaysShow: true,
    name: "SystemManage",
    meta: {
      title: "系统管理"
      // icon: "system"
    },
    children: [{
      path: "user",
      component: "system/user/index",
      name: "SystemUser",
      meta: {
        title: "账号管理"
      }
    }, {
      path: "competence",
      component: "system/competence/index",
      name: "SystemCompetence",
      meta: {
        title: "权限管理"
      }
    }, {
      path: "menu",
      component: "system/menu/index",
      name: "SystemMenu",
      meta: {
        title: "菜单权限"
      }
    }]
  }, {
    path: "/white_list",
    name: "white_list",
    component: _index.default,
    meta: {
      title: "白名单"
    },
    children: [{
      name: "white_index",
      path: "/white_list",
      component: "white_list",
      meta: {
        title: "白名单",
        icon: ""
      }
    }]
  }]
};
var permission = {
  state: {
    routes: [],
    addRoutes: []
  },
  mutations: {
    SET_ROUTES: function SET_ROUTES(state, routes) {
      state.addRoutes = routes;
      state.routes = _router2.constantRoutes.concat(routes);
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes: function GenerateRoutes(_ref) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        // 向后端请求路由数据
        (0, _login.getRouters)({
          pid: process.env.VUE_APP_PID
        }).then(function (res) {
          var newRouter = res.data[0] || [];
          // 后台菜单配置无删除功能 需手动过滤多余的菜单配置项
          newRouter = newRouter.filter(function (item) {
            return item.url !== "activityConfig";
          });
          var accessedRoutes = filterRouter(_router.data, newRouter);
          // console.log('accessedRoutes',accessedRoutes)
          accessedRoutes.push({
            path: "*",
            redirect: "/404",
            hidden: true
          });
          commit("SET_ROUTES", accessedRoutes);
          resolve(accessedRoutes);
        });
      });
    }
  }
};

// 匹配新的路由
function filterRouter(all, now) {
  var filterArr = [];
  all.forEach(function (route) {
    var _obj = {};
    now.forEach(function (element) {
      if (element.url == route.name) {
        if (route.component) {
          // Layout组件特殊处理
          if (route.component.name === "Layout") {
            route.component = _index.default;
          } else {
            route.component = loadView(route.component);
          }
        }
        _obj = route;
        if (element.child && element.child.length) {
          _obj.children = filterRouter(_obj.children, element.child);
        }
        if (JSON.stringify(_obj) != "{}") {
          filterArr.push(_obj);
        }
      }
    });
  });
  return filterArr;
}
// function filterRouter(all, now) {
//   let filterArr = []
//   now.forEach(element => {
//     let _obj = {}
//     all.forEach(route => {
//       if (element.url == route.name) {
//         if (route.component) {
//           // Layout组件特殊处理
//           if (route.component.name === 'Layout') {
//             route.component = Layout
//           } else {
//             route.component = loadView(route.component)
//           }
//         }
//         _obj = route
//       }
//     })

//     if (element.child && element.child.length) {
//       _obj.children = filterRouter(_obj.children, element.child)
//     }
//     if (JSON.stringify(_obj) != '{}') {
//       filterArr.push(_obj)
//     }

//   })
//   return filterArr
// }

var loadView = exports.loadView = function loadView(view) {
  // 路由懒加载
  // return () => import(`@/views/${view}`)
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};
var _default = exports.default = permission;