"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _common = _interopRequireDefault(require("@service/common"));
var _elementUi = require("element-ui");
var organizeList = {
  state: {
    department: [],
    groups: {}
  },
  getters: {
    department: function department(state) {
      return state.department;
    }
  },
  mutations: {
    SET_DEPARTMENT: function SET_DEPARTMENT(state, department) {
      state.department = department;
    }
  },
  actions: {
    // 获取组织结构
    GetOrganizeList: function GetOrganizeList(_ref) {
      var state = _ref.state;
      return new Promise(function (resolve) {
        _common.default.getOrganizeList({
          page: 1,
          size: 999
        }).then(function (res) {
          var code = res.code,
            data = res.data;
          if (code == 200 && data.content && data.content.length) {
            var department = [];
            var groups = {};
            data.content.forEach(function (item) {
              department.push({
                department_name: item.department_name,
                department_id: item.department_id
              });
              groups[item.department_id] = item.group || [];
            });
            sessionStorage.setItem("department", JSON.stringify(department));
            sessionStorage.setItem("groups", JSON.stringify(groups));
          } else {
            _elementUi.Message.error("获取组织结构数据出错");
          }
          resolve(res);
        }).catch(function (e) {
          _elementUi.Message.error("获取组织结构数据出错");
        });
      });
    }
  }
};
var _default = exports.default = organizeList;