"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _activityManageMessageTemplate = _interopRequireDefault(require("@service/activity-manage-message-template"));
var _elementUi = require("element-ui");
var _axios = _interopRequireDefault(require("axios"));
var _utils = require("@/utils");
var platform_id = localStorage.getItem("platform_id");
var activityManage = {
  state: {
    templateList: [],
    gamePlatformList: [],
    gameAttributeTypeList: [],
    gameAwardList: [],
    vtPlatforms: [],
    accountType: false
  },
  getters: {
    templateList: function templateList(state) {
      return state.templateList;
    }
  },
  mutations: {
    SET_TEMPLATELIST: function SET_TEMPLATELIST(state, flag) {
      state.templateList = flag;
    },
    SET_GAME_PLATFORM_LIST: function SET_GAME_PLATFORM_LIST(state, flag) {
      state.gamePlatformList = flag;
    },
    SET_GAME_ATTRTYPE_LIST: function SET_GAME_ATTRTYPE_LIST(state, flag) {
      state.gameAttributeTypeList = flag;
    },
    SET_AWARD_LIST: function SET_AWARD_LIST(state, flag) {
      state.gameAwardList = flag;
    },
    SET_VT_PLATFORMS: function SET_VT_PLATFORMS(state, flag) {
      state.vtPlatforms = flag;
    },
    SET_ACCOUNT_TYPE: function SET_ACCOUNT_TYPE(state, flag) {
      state.accountType = flag;
    }
  },
  actions: {
    // 获取消息模板列表
    GetTemplateList: function GetTemplateList(_ref) {
      var state = _ref.state,
        commit = _ref.commit;
      return new Promise(function (resolve) {
        _activityManageMessageTemplate.default.getList({
          page_no: 1,
          page_size: 20
        }).then(function (res) {
          var code = res.code,
            data = res.data,
            message = res.message;
          if (code == 200) {
            commit("SET_TEMPLATELIST", data.data || []);
            resolve(data.data);
          } else {
            _elementUi.Message.error(message);
          }
          resolve(res);
        }).catch(function (e) {
          _elementUi.Message.error("get data err");
        });
      });
    },
    // 获取游戏平台列表
    getGamePlatformList: function getGamePlatformList(_ref2) {
      var state = _ref2.state,
        commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        (0, _axios.default)({
          baseURL: process.env.VUE_APP_THIRD_API,
          method: 'get',
          url: '/api/getGamePlatform',
          headers: {
            platform: (0, _utils.getPlatformById)(String(platform_id))
          }
        }).then(function (res) {
          if (res.data.code === 200) {
            var gamePlatformList = res.data.data || [];
            commit('SET_GAME_PLATFORM_LIST', gamePlatformList);
            resolve(gamePlatformList);
          } else {
            _elementUi.Message.error(message);
            reject(message);
          }
        }).catch(function (e) {
          _elementUi.Message.error(e.message);
          reject(e.message);
        });
      });
    },
    // 获取游戏属性分类列表
    getGameAttributeTypeList: function getGameAttributeTypeList(_ref3) {
      var state = _ref3.state,
        commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        (0, _axios.default)({
          baseURL: process.env.VUE_APP_THIRD_API,
          method: 'get',
          url: '/api/getGameCategory',
          headers: {
            platform: (0, _utils.getPlatformById)(String(platform_id))
          }
        }).then(function (res) {
          if (res.data.code === 200) {
            var gameAttributeTypeList = res.data.data || [];
            commit('SET_GAME_ATTRTYPE_LIST', gameAttributeTypeList);
            resolve(gameAttributeTypeList);
          } else {
            _elementUi.Message.error(message);
            reject(message);
          }
        }).catch(function (e) {
          _elementUi.Message.error(e.message);
          reject(e.message);
        });
      });
    },
    // vt彩金流水适用平台
    getVTPlatformsList: function getVTPlatformsList(_ref4) {
      var state = _ref4.state,
        commit = _ref4.commit;
      return new Promise(function (resolve, reject) {
        (0, _axios.default)({
          baseURL: process.env.VUE_APP_BASE_API,
          method: 'get',
          url: '/api/platforms',
          headers: {
            platform: (0, _utils.getPlatformById)(String(platform_id))
          }
        }).then(function (res) {
          if (res.data.code === 200) {
            var gameAttributeTypeList = res.data.data.data || [];
            commit('SET_VT_PLATFORMS', gameAttributeTypeList);
            resolve(gameAttributeTypeList);
          } else {
            _elementUi.Message.error(message);
            reject(message);
          }
        }).catch(function (e) {
          _elementUi.Message.error(e.message);
          reject(e.message);
        });
      });
    },
    // 获取奖励列表
    getAwardList: function getAwardList(_ref5) {
      var state = _ref5.state,
        commit = _ref5.commit;
      return new Promise(function (resolve, reject) {
        (0, _axios.default)({
          baseURL: process.env.VUE_APP_BASE_API,
          method: 'get',
          url: '/admin/activity/reward_draw_types',
          headers: {
            platform: (0, _utils.getPlatformById)(String(platform_id))
          }
        }).then(function (res) {
          if (res.data.code === 200) {
            var awardList = res.data.data || [];
            commit('SET_AWARD_LIST', awardList);
            resolve(awardList);
          } else {
            _elementUi.Message.error(message);
            reject(message);
          }
        }).catch(function (e) {
          _elementUi.Message.error(e.message);
          reject(e.message);
        });
      });
    },
    // 获取账户类型
    judgeAccountType: function judgeAccountType(_ref6) {
      var state = _ref6.state,
        commit = _ref6.commit;
      var platform_id = localStorage.getItem('platform_id');
      var type = (0, _utils.getPlatformById)(String(platform_id));
      if (type == 'ph') {
        //lg
        commit('SET_ACCOUNT_TYPE', true);
      } else if (type == 'vn') {
        //vn false
        commit('SET_ACCOUNT_TYPE', false);
      }
    }
  }
};
var _default = exports.default = activityManage;