var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c(
                  "div",
                  { staticClass: "info-box" },
                  [
                    _c(
                      "el-badge",
                      {
                        staticStyle: { "margin-top": "-10px" },
                        attrs: { value: _vm.warnList.length },
                      },
                      [
                        _c("span", { staticClass: "info" }, [
                          _c("i", {
                            staticClass: "el-icon-bell",
                            on: { click: _vm.openInfoDialog },
                          }),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" },
                }),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar },
                }),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", [
                    _c("span", { on: { click: _vm.logout } }, [
                      _vm._v("退出登录"),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "信息",
            visible: _vm.dialogVisible,
            width: "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "550px", "overflow-y": "auto" } },
            [
              _c(
                "el-table",
                { attrs: { border: "", stripe: "", data: _vm.warnList } },
                [
                  _c(
                    "el-table-column",
                    { attrs: { label: "菜单" } },
                    [
                      [
                        _c("div", [
                          _vm._v(
                            "\n              " +
                              _vm._s("优惠活动审核") +
                              "\n            "
                          ),
                        ]),
                      ],
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: { label: "内容", prop: "activity_name" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "提示时间", prop: "created_at" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "前往", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goTo(row.type)
                                  },
                                },
                              },
                              [_vm._v("\n              " + _vm._s("前往"))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }