"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.constructor");
var _hasRole = _interopRequireDefault(require("./hasRole"));
var _hasPermi = _interopRequireDefault(require("./hasPermi"));
var install = function install(Vue) {
  Vue.directive('hasRole', _hasRole.default);
  Vue.directive('hasPermi', _hasPermi.default);

  // 输入浮点数
  Vue.directive('float', {
    bind: function bind(el, _ref) {
      var value = _ref.value;
      value = value || 2; // 默认支持2位小数点
      var input = getInput(el);
      var reg = new RegExp("^\\d+(?:\\.\\d{0,".concat(value, "})?"), 'g');
      input.onkeyup = function (e) {
        input.value = input.value.match(reg);
      };
      input.onblur = function (e) {
        input.value = input.value.match(reg);
      };
    }
  });
};
if (window.Vue) {
  window['hasRole'] = _hasRole.default;
  window['hasPermi'] = _hasPermi.default;
  Vue.use(install); // eslint-disable-line
}
var getInput = function getInput(el) {
  var input = null;
  if (el instanceof HTMLInputElement) {
    input = el;
  } else {
    input = el.getElementsByTagName('input')[0];
  }
  return input;
};
var _default = exports.default = install;