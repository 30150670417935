"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/grow-task-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _screenfull = _interopRequireDefault(require("screenfull"));
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Screenfull',
  data: function data() {
    return {
      isFullscreen: false
    };
  },
  mounted: function mounted() {
    this.init();
  },
  beforeDestroy: function beforeDestroy() {
    this.destroy();
  },
  methods: {
    click: function click() {
      if (!_screenfull.default.enabled) {
        this.$message({
          message: 'you browser can not work',
          type: 'warning'
        });
        return false;
      }
      _screenfull.default.toggle();
    },
    change: function change() {
      this.isFullscreen = _screenfull.default.isFullscreen;
    },
    init: function init() {
      if (_screenfull.default.enabled) {
        _screenfull.default.on('change', this.change);
      }
    },
    destroy: function destroy() {
      if (_screenfull.default.enabled) {
        _screenfull.default.off('change', this.change);
      }
    }
  }
};